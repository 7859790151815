export enum COMMON_ROUTES {
  HOME = "/",
  LOGIN = "/log-in",
  HEALTH_FORM = "/container/form/health",
  HEALTH_FORM_PDP = "/pre-forms/health-form-pdp",
  CAR_FORM = "/container/form/car",
  CAR_FORM_PDP = "/pre-forms/car-form-pdp",
  BIKE_FORM = "/container/form/bike",
  BIKE_FORM_PDP = "/pre-forms/bike-form-pdp",
  TERM_FORM = "/container/form/term",
  TERM_FORM_PDP = "/pre-forms/term-form-pdp",
  NONTERM_FORM = "/container/form/investment",
  NONTERM_FORM_PDP = "/pre-forms/investment-form-pdp",
  PRIVACY_POLICY = "/privacy-policy",
  TERM_AND_CONDTION = "/terms-and-conditions",
  DISCLAIMER = "/disclaimer",
  JIO_DISCLAIMER = "/jio-disclaimer",
  REFUND_AND_CANCELLATION = "/grievance-redressal-policy",
  OLD_REFUND_AND_CANCELLATION = "/refund-and-cancellation",
  ABOUT_US = "/about-us",
  PRODUCT_HEALTH = "/health-insurance",
  LANDING_PRODUCT_CAR = "/landing-car-insurance",
  LANDING_PRODUCT_TW = "/landing-bike-insurance",
  LANDING_PRODUCT_HEALTH = "/landing-health-insurance",
  LANDING_PRODUCT_TERM = "/landing-term-insurance",
  PRODUCT_CAR = "/car-insurance",
  PRODUCT_TW = "/bike-insurance",
  PRODUCT_TERM = "/term-insurance",
  PRODUCT_NON_TERM = "/investment-plans",
  BLOGS = "/blog",
  CONTACT = "/contact-us",
  CAREER = "/career",
  // Car Journey
  CAR_QUOTE = "/Page/Desktop/QuotePages/CarInsurance",
  CUSTOMISE_CAR_PLAN = "/Page/Desktop/QuotePages/CarInsurance/CustomisePlan",
  CAR_PROPOSAL = "/Page/Desktop/ProposalPages/CarInsurance",
  CAR_SUMMARY = "/Page/Desktop/ProposalPages/CarInsurance/ReviewDetails",
  // Term Journey
  TERM_QUOTE = "/Page/Desktop/QuotePages/TermInsurance",
  CUSTOMISE_TERM_PLAN = "/Page/Desktop/QuotePages/TermInsurance/CustomisePlan",
  TERM_PROPOSAL = "/Page/Desktop/ProposalPages/TermInsurance",
  // Health Journey
  HEALTH_QUOTE = "/Page/Desktop/QuotePages/HealthInsurance",
  // General
  BLOG_FACTORS_TO_BE_CONSD = "/Page/Desktop/Blogs/BlogDetailPage/BlogFactorToBeConsd",
  BLOG_UNLOCK_THE_BENEFITS = "/Page/Desktop/Blogs/BlogDetailPage/BlogUnlockTheBenefits",
  BLOG_EVERYTHING_YOU_NEED = "/Page/Desktop/Blogs/BlogDetailPage/BlogEverythingYouNeed",
  BLOG_UNDERSTANDING_OF_DIFFERENT_TYPES = "/Page/Desktop/Blogs/BlogDetailPage/BlogEverythingYouNeed",
  // Car
  BLOG_ALL_YOU_NEED = "/Page/Desktop/Blogs/BlogDetailPage/BlogAllYouNeed",
  BLOG_WHY_DOES_COMP = "/Page/Desktop/Blogs/BlogDetailPage/BlogWhyDoesComp",
  BLOG_MAKING_UNDERSTANDING = "/Page/Desktop/Blogs/BlogDetailPage/BlogMakingUnderstanding",
  BLOG_BENEFITS_OF_CAR = "/Page/Desktop/Blogs/BlogDetailPage/BlogBenefitsOfCar",
  // Bike

  // Health
  BLOG_UNDERSTANDING_DEDUCTIBLE = "/Page/Desktop/Blogs/BlogDetailPage/BlogUnderstandingDeductible",
  BLOG_HEALTH_INSURANCE = "/Page/Desktop/Blogs/BlogDetailPage/BlogHealthInsurance",
  BLOG_CORPORATE_HEALTH = "/Page/Desktop/Blogs/BlogDetailPage/BlogCorporateHealth",
  // Investment
  BLOG_ULIP = "/Page/Desktop/Blogs/BlogDetailPage/BlogULIP",
  // Term

  // Payment Pages
  PAYMENT_STATUS = "/Page/Desktop/ProposalPages/PaymentStatus",
  // IRDAI_OLD = "/IRDAIPage",
  IRDAI = "/isnp-service-tat",
  OURPARTNERS = "/our-partners",
  CLAIM = "/claim",
  GET_THE_APP = "/get-app",

  // Blogs url
  E_INSURANCE_ACCOUNT = "insurance/blog/unlocking-the-benefits-of-electronic-insurance-accounts",
  DOS_AND_DONTS_BIKE_INSURANCE = "two-wheeler-insurance/blog/dos-and-donts-before-buying-a-bike-insurance-policy",
  CAR_INSURANCE_WORKS = "car-insurance/blog/how-does-car-insurance-work-in-india",
  TERM_PLAN_RETURN_PREMIUM = "term-insurance/blog/top-reasons-why-a-term-plan-with-return-of-premium-makes-sense",
  CHOOSE_RIGHT_TERM_POLICY = "term-insurance/blog/how-to-choose-the-right-term-policy",
  CAR_INSURANCE_NATURAL_DISASTERS = "car-insurance/blog/how-car-insurance-can-protect-you-during-natural-disasters",
  BIKE_INSURANCE_TERMS = "two-wheeler-insurance/blog/what-are-the-terms-used-in-bike-insurance",
  HEALTH_INSURANCE_CLAIM_RATIO = "health-insurance/blog/top-health-insurance-companies-with-best-claim-settlement-ratio",
  TRANSFER_CAR_INSURANCE = "car-insurance/blog/how-to-transfer-your-car-insurance-policy",
  HEALTH_INSURANCE_PREMIUM_FACTORS = "health-insurance/blog/what-are-the-factors-affecting-health-insurance-premiums",
  NO_CLAIM_BONUS_CAR_INSURANCE = "car-insurance/blog/no-claim-bonus-in-car-insurance",
  NEED_TERM_LIFE_INSURANCE = "term-insurance/blog/Im-healthy-do-i-still-need-to-buy-term-life-insurance",
  MANDATORY_BIKE_INSURANCE = "two-wheeler-insurance/blog/why-is-it-mandatory-to-buy-a-bike-insurance-policy-in-india",
  SUPER_TOP_UP_HEALTH_INSURANCE = "health-insurance/blog/should-you-buy-a-super-top-up-health-insurance-policy",
  BIKE_INSURANCE_DEDUCTIBLE = "two-wheeler-insurance/blog/understanding-bike-insurance-deductible",
  IDV_CAR_INSURANCE = "car-insurance/blog/idv-in-car-insurance",
  CLAIM_ZERO_DEPRECIATION_BIKE = "two-wheeler-insurance/blog/a-step-by-step-guide-to-claiming-zero-depreciation-bike-insurance",
  RENEW_CAR_INSURANCE_ON_TIME = "car-insurance/blog/why-is-it-important-to-renew-your-car-insurance-policy-on-time",
  DIFFERENT_TYPES_OF_INSURANCE = "insurance/blog/understanding-different-types-of-insurance",
  FAMILY_HEALTH_INSURANCE = "health-insurance/blog/overview-of-health-insurance-for-families",
  PERSONAL_ACCIDENT_INSURANCE_FACTORS = "insurance/blog/factors-to-be-considered-while-buying-personal-accident-insurance-in-india",
  PERSONAL_ACCIDENT_COVER_CAR = "car-insurance/blog/everything-you-need-to-know-about-personal-accident-cover",
  CAR_INSURANCE_OVERVIEW = "car-insurance/blog/all-you-need-to-know-about-car-insurance",
  COMPREHENSIVE_CAR_INSURANCE_COST = "car-insurance/blog/why-does-a-comprehensive-car-insurance-cost-more-than-a-third-party-liability-car-insurance",
  IDV_BIKE_INSURANCE = "two-wheeler-insurance/blog/making-understanding-of-idv-easy-for-you",
  HEALTH_INSURANCE_DEDUCTIBLES = "health-insurance/blog/understanding-deductibles-and-co-pays",
  HEALTH_INSURANCE_TOPUP_PLANS = "health-insurance/blog/health-insurance-top-up-plans",
  CORPORATE_HEALTH_INSURANCE = "health-insurance/blog/do-you-have-a-corporate-health-insurance-policy-find-out-if-it-is-enough",
  ULIP_INVESTMENT_PLAN = "investment-plans/blog/unit-linked-insurance-plan-ulip",
  CAR_INSURANCE_RENEWAL_BENEFITS = "car-insurance/blog/benefits-of-car-insurance-renewal",
  TRANSFER_CAR_INSURANCE_POLICY = "car-insurance/blog/how-to-transfer-your-car-insurance-policy",
}
